import { getUser } from 'pared/utils/user'

export interface IBusinessLabels {
  corporate: string
  director: string
  isDirectorClickable: boolean
  isGmClickable: boolean
  isStoreClickable: boolean
  showGm: boolean
  store: string
  accessGroupType?: string
  corporateCustomNavTitle?: string
}

export function getBusinessLabels(brand: string): IBusinessLabels {
  const user = getUser()
  switch (brand) {
    case 'rmg': // Romano's Macaroni Grill
    case 'rmg_nso':
    case 'sullivans': // Sullivan's Steakhouse
    case 'sullivans_nso':
    case 'eddiemerlots': // Eddie Merlot's
    case 'eddiemerlots_nso':
      return {
        corporate: 'Corporate',
        director: 'DO/MGP',
        isDirectorClickable: true,
        isGmClickable: true,
        isStoreClickable: true,
        showGm: true,
        store: 'Store',
      }
    case 'papajohns': // Papa Johns
    case 'papajohns_demo': // Papa Johns Demo
      return {
        corporate: 'Corporate',
        director: 'Supervisor',
        isDirectorClickable: true,
        isGmClickable: true,
        isStoreClickable: true,
        showGm: true,
        store: 'Store',
      }
    case 'irmg_bk':
    case 'demo_bk':
      return {
        corporate: 'Corporate',
        director: 'Region',
        isDirectorClickable: false,
        isGmClickable: true,
        isStoreClickable: true,
        showGm: false,
        store: 'Store',
      }
    case 'irmg_pop':
    case 'demo_pop':
      return {
        corporate: 'Corporate',
        director: 'District Manager',
        isDirectorClickable: false,
        isGmClickable: true,
        isStoreClickable: true,
        showGm: false,
        store: 'Store',
      }
    case 'timoney_bk':
      return {
        corporate: 'Corporate',
        director: 'Region',
        isDirectorClickable: false,
        isGmClickable: true,
        isStoreClickable: true,
        showGm: false,
        store: 'Store',
        corporateCustomNavTitle: 'Daily Dashboard',
      }
    case 'viking_bk':
      return {
        corporate: 'Corporate',
        director: 'District Manager',
        isDirectorClickable: false,
        isGmClickable: true,
        isStoreClickable: true,
        showGm: false,
        store: 'Store',
        corporateCustomNavTitle: 'Export Accounting',
      }
    case 'jsc_bk':
    case 'jsc_bk_nso':
      return {
        corporate: 'Corporate',
        director: 'District Leader',
        isDirectorClickable: false,
        isGmClickable: true,
        isStoreClickable: true,
        showGm: false,
        store: 'Store',
      }
    case 'sandbox_bk':
    case 'supreme_bk':
    case 'supreme_pop':
    case 'ace_pop':
      return {
        corporate: 'Corporate',
        director: 'District Manager',
        isDirectorClickable: false,
        isGmClickable: true,
        isStoreClickable: true,
        showGm: false,
        store: 'Store',
      }
    case 'mbn_bk':
      return {
        corporate: 'Corporate',
        director: 'DM',
        isDirectorClickable: false,
        isGmClickable: true,
        isStoreClickable: true,
        showGm: true,
        store: 'Store',
      }
    case 'ghai_pop':
      return {
        corporate: 'Corporate',
        director: 'District Manager',
        isDirectorClickable: false,
        isGmClickable: true,
        isStoreClickable: true,
        showGm: false,
        store: 'Store',
      }

    case 'cava':
      return {
        corporate: 'Corporate',
        director: 'AL',
        isDirectorClickable: false,
        isGmClickable: false,
        isStoreClickable: true,
        showGm: true,
        store: 'Restaurant',
        accessGroupType: user.accessGroupType,
      }
    case 'jitb':
      return {
        corporate: 'Corporate',
        director: 'Region',
        isDirectorClickable: false,
        isGmClickable: true,
        isStoreClickable: true,
        showGm: false,
        store: 'Store',
      }
    case 'lfr':
      return {
        corporate: 'Brand',
        director: 'Brand',
        isDirectorClickable: false,
        isGmClickable: true,
        isStoreClickable: false,
        showGm: true,
        store: 'Store',
      }
    case 'lfr_ef':
    case 'lfr_tr':
    case 'lfr_sn':
    case 'lfr_lv':
    case 'lfr_vb':
    case 'lfr_tu':
    case 'lfr_ws':
    case 'lfr_jn':
    case 'lfr_mm':
    case 'lfr_ol':
    case 'lfr_dt':
    case 'lfr_uv':
    case 'lfr_bth':
      return {
        corporate: 'Brand',
        director: 'Director',
        isDirectorClickable: true,
        isGmClickable: true,
        isStoreClickable: true,
        showGm: true,
        store: 'Store',
      }
    case 'wingitnorth':
      return {
        corporate: 'Corporate',
        director: 'Director',
        isDirectorClickable: false,
        isGmClickable: false,
        isStoreClickable: true,
        showGm: false,
        store: 'Store',
      }
    case 'mwb':
      return {
        corporate: 'Corporate',
        director: 'Regional Director',
        isDirectorClickable: false,
        isGmClickable: false,
        isStoreClickable: true,
        showGm: false,
        store: 'Store',
      }
    case 'burgerworks':
      return {
        corporate: 'Corporate',
        director: 'Area',
        isDirectorClickable: false,
        isGmClickable: false,
        isStoreClickable: true,
        showGm: false,
        store: 'Store',
      }
    case 'dh_wb':
      return {
        corporate: 'Corporate',
        director: 'Region',
        isDirectorClickable: false,
        isGmClickable: false,
        isStoreClickable: true,
        showGm: false,
        store: 'Store',
      }
    case 'bibibop':
      return {
        corporate: 'Corporate',
        director: 'District Manager',
        isDirectorClickable: false,
        isGmClickable: false,
        isStoreClickable: true,
        showGm: true,
        store: 'Store',
      }
    case 'fw_wingstop':
      return {
        corporate: 'Corporate',
        director: 'District Manager',
        isDirectorClickable: false,
        isGmClickable: false,
        isStoreClickable: true,
        showGm: false,
        store: 'Store',
      }
    default:
      return {
        corporate: 'Corporate',
        director: 'Director',
        isDirectorClickable: true,
        isGmClickable: true,
        isStoreClickable: true,
        showGm: true,
        store: 'Store',
      }
  }
}
