import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import { useVariables } from 'pared/Routes/renderer/variables'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiDataType } from '../../types'

type IMetricDataType = Record<
  string,
  {
    name: string
    unit: string
    value: number
  }
>

const query = gql`
  query listStorePerforamceTable(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
  ) {
    trendLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
    ) {
      nodes {
        startDate
        endDate
        businessQuarter
        businessMonth
        businessWeek
        metricData
      }
    }

    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        metricSummaryData
      }
    }
  }
`

const formatDateString = (dateStr: string) => moment(dateStr).format('M/D/YY')

const format = (data: IMetricDataType) =>
  Object.entries(data).reduce((result, [key, value]) => {
    switch (value.unit) {
      case 'PERCENTAGE':
      case 'DOLLAR':
        return {
          ...result,
          [_.camelCase(key)]: value.value * 100,
        }
      default:
        return {
          ...result,
          [_.camelCase(key)]: value.value,
        }
    }
  }, {})

export const dhWbStorePerformanceConfigs = {
  date: 'string',
  netSales: 'price',
  laborTotalCost: 'price',
  laborTotalCostPercent: 'percent',
  cogsPercentage: 'percent',
} as const

const useStorePerformance = () => {
  const { variables } = useVariables()
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()

  const dateType = variables.date?.value.type
  const iGroupBy = ((t) => {
    switch (t) {
      case 'year':
        return 'business_quarter'

      case 'quarter':
        return 'business_month'

      default:
        return 'business_week'
    }
  })(dateType)

  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iGroupBy,
      iFilter: {
        location_ids: groupFilter?.ids,
        metrics: [
          'net_sales',
          'labor_total_cost',
          'labor_total_cost_percent',
          'cogs_percentage',
        ],
      },
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const trendData = data?.trendLocationMetricValues.nodes as
        | {
            startDate: string
            endDate: string
            businessQuarter: number
            businessMonth: number
            businessWeek: number
            metricData: {
              [key: string]: {
                name: string
                unit: string
                value: number
              }
            }
          }[]
        | undefined

      const locationData = data?.listLocationMetricValues.nodes as
        | {
            metricSummaryData: {
              [key: string]: {
                name: string
                unit: string
                value: number
              }
            }
          }[]
        | undefined

      if (!trendData || !locationData) return null

      const resultData = trendData.map((d) => {
        const date = ((t) => {
          const startStr = formatDateString(d.startDate)
          const endDateStr = formatDateString(d.endDate)
          switch (t) {
            case 'year':
              return `Quarter ${d.businessQuarter} (${startStr} - ${endDateStr})`

            case 'quarter':
              return `Period ${d.businessMonth} (${startStr} - ${endDateStr})`

            default:
              return `Week ${d.businessWeek} (${startStr} - ${endDateStr})`
          }
        })(dateType)

        return {
          ...format(d.metricData),
          date,
          id: date as string,
          parentId: 'root',
        }
      })

      const summaryData =
        locationData.find((s) => !!s.metricSummaryData)?.metricSummaryData || {}
      const resultSummary = [
        {
          ...format(summaryData),
          date: 'PTD Actual',
          id: 'summary',
          parentId: 'root',
        },
      ]

      return [...resultSummary, ...resultData]
    }, [data]),
    loading,
  }
}

export default useStorePerformance
