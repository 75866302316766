import { IConfigsType } from '../../types'

const dhInventory: IConfigsType = {
  dh_wb: {
    '/:brand/corporate_inventory': {
      variables: [
        'corporateGroup',
        {
          type: 'items',
          initialSelection: ['28', 'category-28'],
          categoriesOnly: true,
          root: 'EXPO_COGS_CATEGORY_ROOT',
        },
      ],
      groupFilter: {
        api: 'corporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: ['last_week', 'week'],
        defaultType: 'last_week',
      },
      title: {
        type: 'title',
        title: 'Inventory',
      },
      kpis: {
        type: 'list',
        api: 'dhWbListItemMetricValues',
        fieldWidth: '160px',
        fields: [
          {
            content: {
              key: 'actualCogs',
            },
            bottom: 'Actual COGS',
          },
          {
            content: {
              key: 'theoreticalCogs',
            },
            bottom: 'Theoretical COGS',
          },
          {
            content: {
              key: 'actualCogsPercent',
            },
            bottom: 'Actual COGS %',
          },
          {
            content: {
              key: 'theoreticalCogsPercent',
            },
            bottom: 'Theoretical COGS %',
          },
          {
            content: {
              key: 'cogsVariance',
              color: '<%- value > 0 ? colors.Pomodoro : "black" %>',
            },
            bottom: 'COGS Variance',
          },
          {
            content: {
              key: 'cogsVariancePercent',
              color: '<%- value > 0 ? colors.Pomodoro : "black" %>',
            },
            bottom: 'COGS Variance %',
          },
        ],
      },
      categoryFilter: {
        type: 'select',
        value: 'items.value',
        onChange: 'items.onChange',
        options: 'items.options',
        label: 'Category',
      },
      breakdownByStore: {
        type: 'table-v2',
        api: 'inventory',
        sorting: [
          {
            id: '<%- corporateGroup?.tableColumns[0].key %>',
            desc: true,
          },
        ],
        rowPinning: {
          top: ['summary'],
        },
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            sortable: true,
          },
          {
            key: 'displayParentCategoryName',
            title: 'Category',
            sortable: true,
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'actualCogs',
            title: 'Actual COGS',
            sortable: true,
            unit: 'dollar',
          },
          {
            key: 'theoreticalCogs',
            title: 'Theoretical COGS',
            sortable: true,
            unit: 'dollar',
          },
          {
            key: 'actualCogsPercent',
            title: 'Actual COGS %',
            decimal: 2,
            isDecimal: true,
            sortable: true,
          },
          {
            key: 'theoreticalCogsPercent',
            title: 'Theoretical COGS %',
            decimal: 2,
            isDecimal: true,
            sortable: true,
          },
          {
            key: 'cogsVariance',
            title: 'COGS Variance',
            unit: 'dollar',
            sortable: true,
            color: '<%- value > 0 ? colors.Pomodoro : "black" %>',
          },
          {
            key: 'cogsVariancePercent',
            title: 'COGS Variance %',
            decimal: 2,
            isDecimal: true,
            sortable: true,
            color: '<%- value > 0 ? colors.Pomodoro : "black" %>',
          },
        ],
      },
    },
  },
}

export default dhInventory
