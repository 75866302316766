import camelCase from 'lodash/camelCase'

type IMetricDataType = Record<
  string,
  {
    name?: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT' | 'SECONDS'
    value: number
  }
>

const format = (data: IMetricDataType) =>
  Object.entries(data).reduce((result, [key, value]) => {
    switch (value.unit) {
      case 'PERCENTAGE':
      case 'DOLLAR':
        return {
          ...result,
          [camelCase(key)]: value.value * 100,
        }
      case 'SECONDS':
        return {
          ...result,
          [camelCase(key)]: value.value / 60.0,
        }
      default:
        return {
          ...result,
          [camelCase(key)]: value.value,
        }
    }
  }, {})

export default format
