import useDhWbListLocationDeliveryKpis, {
  dhWbListLocationDeliveryKpisConfigs,
} from './useListLocationDeliveryKpis'
import useDhWbListLocationGroupDeliveryKpis, {
  dhWbListLocationGroupDeliveryKpisConfigs,
} from './useListLocationGroupDeliveryKpis'
import useDhWbListItemMetricValues, {
  dhWbListItemMetricValuesConfigs,
} from './useListItemMetricValues'
import useDhWbListLocationGroupGuestKpis, {
  dhWbListLocationGroupGuestKpisConfigs,
} from './useListLocationGroupGuestKpis'
import useDhWbListLocationGroupLossPreventionKpis, {
  dhWbListLocationGroupLossPreventionKpisConfigs,
} from './useListLocationGroupLossPreventionKpis'
import useDhWbListLocationGroupSalesmanshipKpis, {
  dhWbListLocationGroupSalesmanshipKpisConfigs,
} from './useListLocationGroupSalesmanshipKpis'
import useDhWbListLocationGuestKpis, {
  dhWbListLocationGuestKpisConfigs,
} from './useListLocationGuestKpis'
import useDhWbListLocationLossPreventionKpis, {
  dhWbListLocationLossPreventionKpisConfigs,
} from './useListLocationLossPreventionKpis'
import useDhWbListStorePerformanceKpis, {
  dhWbListStorePerformanceKpisConfigs,
} from './useListStorePerformanceKpis'
import useDhWbListSystemwideOverviewKpis, {
  dhWbListSystemwideOverviewKpisConfigs,
} from './useListSystemwideOverviewKpisKpis'

export const configs = {
  dhWbListSystemwideOverviewKpis: dhWbListSystemwideOverviewKpisConfigs,
  dhWbListStorePerformanceKpis: dhWbListStorePerformanceKpisConfigs,
  dhWbListLocationGroupGuestKpis: dhWbListLocationGroupGuestKpisConfigs,
  dhWbListLocationLossPreventionKpis: dhWbListLocationLossPreventionKpisConfigs,
  dhWbListLocationGroupLossPreventionKpis:
    dhWbListLocationGroupLossPreventionKpisConfigs,
  dhWbListLocationGroupSalesmanshipKpis:
    dhWbListLocationGroupSalesmanshipKpisConfigs,
  dhWbListLocationGroupDeliveryKpis: dhWbListLocationGroupDeliveryKpisConfigs,
  dhWbListLocationDeliveryKpis: dhWbListLocationDeliveryKpisConfigs,
  dhWbListLocationGuestKpis: dhWbListLocationGuestKpisConfigs,
  dhWbListItemMetricValues: dhWbListItemMetricValuesConfigs,
}

export const api = {
  dhWbListSystemwideOverviewKpis: useDhWbListSystemwideOverviewKpis,
  dhWbListStorePerformanceKpis: useDhWbListStorePerformanceKpis,
  dhWbListLocationGroupGuestKpis: useDhWbListLocationGroupGuestKpis,
  dhWbListLocationLossPreventionKpis: useDhWbListLocationLossPreventionKpis,
  dhWbListLocationGroupLossPreventionKpis:
    useDhWbListLocationGroupLossPreventionKpis,
  dhWbListLocationGroupSalesmanshipKpis:
    useDhWbListLocationGroupSalesmanshipKpis,
  dhWbListLocationGroupDeliveryKpis: useDhWbListLocationGroupDeliveryKpis,
  dhWbListLocationDeliveryKpis: useDhWbListLocationDeliveryKpis,
  dhWbListLocationGuestKpis: useDhWbListLocationGuestKpis,
  dhWbListItemMetricValues: useDhWbListItemMetricValues,
}
