import { useMemo } from 'react'

import { IApiDataType } from '../../types'
import buildLocationEmployeeMetricValuesHook from '../utils/buildLocationEmployeeMetricValuesHook'

const employeeSalesmanshipDataConfigs = {
  grossSales: 'price',
  checkCount: 'number',
  checkAverage: 'price',
  laborShiftCount: 'number',
  salesPerLaborHour: 'price',
  itemAttachmentRateDesserts: 'percent',
  itemAttachmentRateBeverages: 'percent',
  itemAttachmentRateBacon: 'percent',
  itemAttachmentRateCheese: 'percent',
  itemAttachmentRateJalapeno: 'percent',
  upsizedCountPercentage: 'percent',
} as const

export const dhWbListEmployeeSalesmanshipDataConfigs = {
  ...employeeSalesmanshipDataConfigs,
  employeeName: 'string',
} as const

const useCommonEmployeeSalesmanshipData = buildLocationEmployeeMetricValuesHook(
  {
    codes: [
      'gross_sales',
      'check_count',
      'check_average',
      'labor_shift_count',
      'sales_per_labor_hour',
      'upsized_count_percentage',
    ],
    groups: ['Expo Module - Item Attachment Rate'],
  },
)

const useListEmployeeSalesmanshipDataData = () => {
  const { data: commonData, loading } = useCommonEmployeeSalesmanshipData()

  return {
    data: useMemo((): IApiDataType => {
      if (!commonData) return null

      const cleanedCommonData = commonData?.filter((cd) => {
        if (cd?.grossSales) {
          return true
        }
        return false
      })

      const keys = cleanedCommonData.reduce((sum, cd) => {
        const rowKeys = Object.keys(cd || {}) || []
        const newKeys = rowKeys?.filter((rk) => !sum.includes(rk))
        return [...sum, ...newKeys]
      }, [] as string[])

      const keyInfo = keys
        .filter((k) => Object.keys(employeeSalesmanshipDataConfigs).includes(k))
        .reduce(
          (r, k) => {
            const kpiData =
              cleanedCommonData?.map((cd) => cd[k] as number) || []
            const sortedData = [...kpiData].sort((a, b) => a - b) || []

            return {
              ...r,
              [k]: {
                sortedData,
              },
            }
          },
          {} as {
            [k: string]: {
              sortedData: number[]
            }
          },
        )

      const newData =
        cleanedCommonData?.map((cd) => {
          return {
            ...cd,
            ...Object.keys(cd).reduce((r, c) => {
              if (Object.keys(employeeSalesmanshipDataConfigs).includes(c)) {
                const sorted = keyInfo[c].sortedData
                const rank =
                  sorted.findIndex((sortedItem) => sortedItem === cd[c]) + 1
                return {
                  ...r,
                  [`${c}Heatmap`]: ((rank - 1) / (sorted.length - 1)) * 100,
                }
              } else {
                return r
              }
            }, {}),
          }
        }) || []

      return newData
    }, [commonData, loading]),
    loading: loading,
  }
}

export default useListEmployeeSalesmanshipDataData
